import React from 'react';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
import Spotify from '../../Spotify';
import '../../../styles/main.css'
import '../index.css'
import { CodeBlock, dracula } from "react-code-blocks";

class MarkovBot extends React.Component {
    code = `    {
        ...,
        "is": [
            "grey",
            "big"
        ],
        ...
    }`

    render() {
        return (
            <>
                <div className="back">
                    <Link to="/about"> <code> &lt; back to about </code> </Link>
                    <Spotify {...{side: 'right', hide: 'true'}}></Spotify>
                </div>
                <div className="description">
                    <div className="aurora">
                        <h1> Markov Bot </h1>
                    </div>
                    <h2>What is this?</h2>
                    <p>This Discord bot attempts that generates sentences (that sometimes make sense) based on previous 
                        messages in a Discord server. It is based on the Markov Chain algorithm. The bot will 
                        randomly send sentences generated from this model.</p>
                    <h2>How does it work?</h2>
                    <h3 style={{border: '1px rgb(80,80,80) solid', borderRadius: '3px', color: "rgb(176,176,176)", padding: '0.8rem' }}>
                        "The Markov Chain model is a probabilistic model that is used to generate text. It 
                        is based on the idea that a sentence is generated by a sequence of words. 
                        The sequence of words is determined by the previous words. The probability 
                        of a word is determined by the number of times it is followed by the previous word."
                    </h3>
                    <p>In order for the Markov Chain model to work, it first needs data. 
                        For any given sentence, the Markov Chain model will map words in a 
                        sequential order to the next word. For example, in the sentence, 
                        "The cat is grey and is big," the Markov Chain model will map "
                        The" to "cat" and "cat" to "grey" and so on. In this implementation, 
                        I've mapped this data as key-value pairs in JSON, with values being 
                        an array of words that have followed the key.
                    </p>
                    <p>If multiple words follow a key, the probability of the next 
                        word being chosen is determined by the number of times the 
                        key has been followed. Using the previous example sentence, 
                        the probability of "grey" being chosen after the word "is" 
                        depends on the number of times the word "is" has been followed. 
                        In this case, the probability is 1/2. The JSON object would look 
                        like this:</p>
                    <CodeBlock 
                        text={this.code}
                        language={'javascript'}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                    <p>
                    You might now ask, how are the sentences generated? Using 
                    random numbers, the model will choose a random starting 
                    word, and then choose the next word based on the probability 
                    of the previous word. If the randomly chosen starting word is 
                    "is" like in the example above, the model will generate a random 
                    number based on how many entries are in the array (in this case, two) 
                    and select the next word based on that index. It repeats this process 
                    over and over for the chosen word; if it chose "grey," it would look 
                    for "grey" in the JSON and randomly choose from what words usually follow it.
                    </p>
                    <p>The more limited the data set, the more similar the sentences will be. 
                        That's why we're using Discord - new incoming messages as well as old 
                        ones are "training" the model.
                    </p>
                    <a href="https://github.com/jensenzhng/markov-discord-bot" style={{color: "rgb(120 108 255)", fontSize: '1.3rem'}}  target="_blank" rel="noreferrer">Github</a>
                </div>
                <Footer />
            </>
        )
    }
}

export default MarkovBot;